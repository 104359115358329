













































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { appointment } from "@/shared/dtos/appointment";
import { appointmentCalendar } from "@/shared/dtos/appointment-calendar";
import appointmentModule from "@/store/modules/appointment-module";
import resourceModule from "@/store/modules/resource-module";
import { resource } from "@/shared/dtos/resource";
import { UtilsPaciente } from "@/utils/utils-paciente";
import { UtilsNotify } from "@/utils/utils-notify";
import { RouterNames } from "@/router/routernames";
import { UtilsEncript } from "@/utils/utils-encript";
@Component({
  components: {
    AddCita: () => import("./AddCita.vue"),
    ModificarCita: () => import("./ModificarCita.vue"),
  },
})
export default class Calendario extends Vue {
  public focus = "";
  public tipoDeVisualizacion = "month";
  public toggle_exclusive = 2;
  public TipoDeVisualizaciones = {
    month: "Mes",
    week: "Semana",
    day: "Dia",
    "4day": "4 Dias",
  };
  public start: any = null;
  public today = "";
  public end: any = null;
  public selectedEvent: appointmentCalendar = new appointmentCalendar();
  public selectedElement = null;
  public selectedOpen = false;
  public dateclicked: Date = new Date();
  public clickday: boolean = false;
  public AddCitaShow: boolean = false;
  public ModifyCitaShow: boolean = false;
  public created() {
    appointmentModule.getappointments();
  }
  public get events(): appointmentCalendar[] {
    let appointments: appointmentCalendar[] = [];
    for (let i = 0; i < appointmentModule.appointments.length; i++) {
      const element = appointmentModule.appointments[i];
      let appointment: appointmentCalendar = new appointmentCalendar();
      appointment.name = element.Subject;
      let newDate = new Date(element.StartDate);
      appointment.start = this.formatDate(newDate, !element.AllDay);
      appointment.end = this.formatDate(
        new Date(element.EndDate),
        !element.AllDay
      );
      appointment.classtextcolor = element.Resource.Color + "--text";
      appointment.color = element.Resource.Color;
      appointment.icono = element.Resource.Icono;
      appointment.metadata = element;
      appointments.push(appointment);
    }
    return appointments;
  }
  public get GetSelectedEvent() {
    if (this.selectedEvent.metadata === undefined) {
      if (this.events.length > 0) {
        return this.events[0].metadata;
      }
      return undefined;
    }
    return this.selectedEvent.metadata;
  }
  public get resources() {
    return resourceModule.resources;
  }
  public get title() {
    const { start, end } = this;
    if (!start || !end) {
      return "";
    }
    const startMonth = this.monthFormatter(start);
    const endMonth = this.monthFormatter(end);
    const startYear = start.year;
    const endYear = end.year;
    const startDay = start.day;
    const endDay = end.day;

    switch (this.tipoDeVisualizacion) {
      case "month":
        return `${startMonth}  ${startYear}`;
      case "week":
      case "4day":
        return `${startDay} ${endDay} ${startMonth} ${startYear} `;
      case "day":
        return `${startDay} ${startMonth} ${startYear}`;
    }

    return "";
  }

  public AddCita(event: any, clickday: boolean) {
    if (clickday) {
      this.dateclicked = new Date(event.date + " " + event.time);
      this.clickday = clickday;
    }

    this.AddCitaShow = true;
  }
  public ImgPaciente(Event: appointmentCalendar) {
    return UtilsPaciente.getrutaImagenFromAppoitmentCalendar(Event);
  }
  public NombrePaciente(Event: appointmentCalendar) {
    let nombre = "Paciente no encontrado";
    try {
      if (
        Event.metadata.Paciente.nombre !== undefined ||
        Event.metadata.Paciente.nombre !== null
      ) {
        return (
          Event.metadata.Paciente.nombre +
          " " +
          Event.metadata.Paciente.apellidos
        );
      }
    } catch (error) {}
    return nombre;
  }

  public get monthFormatter() {
    try {
      //@ts-ignore
      return this.$refs.calendar.getFormatter({
        timeZone: "UTC",
        month: "long",
      });
    } catch (error) {}
  }
  public mounted() {
    try {
      //@ts-ignore
      this.$refs.calendar.checkChange();
    } catch (error) {}
  }
  @Watch("tipoDeVisualizacion")
  public botones_visualizacion_movil() {
    switch (this.tipoDeVisualizacion) {
      case "month":
        this.toggle_exclusive = 2;
      case "week":
        this.toggle_exclusive = 1;
      case "4day":
        this.toggle_exclusive = 3;
      case "day":
        this.toggle_exclusive = 0;
    }
  }

  public MirarEvent(Event: appointmentCalendar) {
    this.ModifyCitaShow = true;
  }

  public getTipoRecurso(Event: appointmentCalendar) {
    try {
      return Event.metadata.Resource.ResourceName;
    } catch (error) {}
    return "";
  }

  public getDetalles(Event: appointmentCalendar) {
    let descripcion = "No hay detalles de la cita.";
    try {
      if (
        Event.metadata.Description !== undefined &&
        Event.metadata.Description !== null &&
        Event.metadata.Description !== ""
      ) {
        return Event.metadata.Description;
      }
    } catch (error) {}
    return descripcion;
  }
  public edit_paci(Event: appointmentCalendar) {
    try {
      if (
        Event.metadata.Paciente.numero !== undefined ||
        Event.metadata.Paciente.numero !== null
      ) {
        this.$router.push({
          name: RouterNames.pacientesedit,
          query: {
            id: UtilsEncript.Encriptar(
              Event.metadata.Paciente.numero.toString()
            ),
          },
        });
      }
    } catch (error) {}
  }

  public viewDay({ date }: any) {
    this.focus = date;
    this.tipoDeVisualizacion = "day";
  }
  public getEventColor(event: any) {
    return event.color;
  }
  public setToday() {
    this.focus = this.today;
  }
  public prev() {
    //@ts-ignore
    this.$refs.calendar.prev();
  }
  public next() {
    //@ts-ignore
    this.$refs.calendar.next();
  }
  public showEvent({ nativeEvent, event }: any) {
    const open = () => {
      this.selectedEvent = event;
      this.selectedElement = nativeEvent.target;
      setTimeout(() => (this.selectedOpen = true), 10);
    };
    if (this.selectedOpen) {
      this.selectedOpen = false;
      setTimeout(open, 10);
    } else {
      open();
    }
    nativeEvent.stopPropagation();
  }
  public updateRange({ start, end }: any) {
    //EVENTOS CARGA AQUI
    this.start = start;
    this.end = end;
  }

  public formatDate(a: any, withTime: any) {
    return withTime
      ? `${a.getFullYear()}-${
          a.getMonth() + 1
        }-${a.getDate()} ${a.getHours()}:${a.getMinutes()}`
      : `${a.getFullYear()}-${a.getMonth() + 1}-${a.getDate()}`;
  }
  EliminarEvent(cita: appointmentCalendar) {
    //@ts-ignore
    this.$vs.dialog({
      type: "confirm",
      color: "danger",
      title: `Confirme la eliminación de la cita`,
      acceptText: "Aceptar",
      cancelText: "Cancelar",
      text: "¿Seguro que quiere eliminar la cita de " + cita.name + " ?.",
      accept: () => this.acceptAlertEliminar(cita),
    });
    //https://github.com/lusaxweb/vuesax/blob/master/src/functions/vsDialog/index.vue
  }
  public cambiarCita(cita: appointmentCalendar, resource: resource) {
    //@ts-ignore
    this.$vs.dialog({
      type: "confirm",
      color: "danger",
      title: `Confirme el cambio`,
      acceptText: "Aceptar",
      cancelText: "Cancelar",
      text:
        "¿Seguro que quiere cambiar la cita de " +
        cita.metadata.Resource.ResourceName +
        " a " +
        resource.ResourceName +
        "?",
      accept: () => this.acceptAlertModificar(cita, resource),
    });
    //https://github.com/lusaxweb/vuesax/blob/master/src/functions/vsDialog/index.vue
  }
  public acceptAlertEliminar(cita: appointmentCalendar) {
    this.selectedOpen = false;
    appointmentModule.eliminarappointment(cita.metadata);
    UtilsNotify.NotificacionDanger(
      "CITA ELIMINADA",
      "La cita se ha eliminado correctamente"
    );
  }
  public acceptAlertModificar(cita: appointmentCalendar, resource: resource) {
    cita.metadata.Resource = resource;
    cita.metadata.ResourceID = resource.ResourceID;
    this.selectedOpen = false;
    appointmentModule.modificarappointment(cita.metadata);
    UtilsNotify.Notificacion(
      "Cita cambiada",
      "La cita ha cambiado de estado " + resource.ResourceName,
      resource.Color
    );
  }
  public Imprimir(cita: appointment) {
    appointmentModule.descargarappointmentPDF(cita);
  }
  public enviar_email(cita: appointment) {
    appointmentModule.modificarappointment(cita).then(() => {
      UtilsNotify.NotificacionPrimary(
        "Cita enviada",
        "La cita se ha enviado correctamente "
      );
    });
  }
}
